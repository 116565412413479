import Quote from '@/models/Quote';
import Service from '@/models/Service';
import {
	ActionTree,
	GetterTree,
	Module,
} from 'vuex';

interface State {
	[propName: string]: any;
}

const Actions: ActionTree<State, StoreTypes.RootState> = {
	dispatchAnalyticsAction({ dispatch, rootState }, payload: {label: string, value: any}) {
		const newValue = { ...payload.value, ...rootState.utmData };
		if (rootState.googleDebugMode) {
			Object.assign(newValue, { debug_mode: true });
			console.log(payload.label, newValue);
		}
		dispatch('analytics/action', {
			type: 'UX',
			label: payload.label,
			value: newValue,
			step: rootState.reservation.step,
		} as Analytics.ActionParams, { root: true });
	},

	clickedChooseCreditCard({ dispatch }, buttonName) {
		dispatch('dispatchAnalyticsAction', {
			label: 'choseCreditCardButton',
			value: {
				buttonName,
			},
		});
	},

	fetchReservationPriceQuotes({ dispatch }, payload) {
		dispatch('dispatchAnalyticsAction', {
			label: 'fetchReservationPriceQuotes',
			value: payload,
		});
	},

	loginAttempt({ dispatch }, payload: {email: string, location: string}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'loginAttempt',
			value: payload,
		});
	},

	genericButtonPressed({ dispatch }, payload: {buttonName: string, fromInfo: string}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'genericButtonPressed',
			value: payload,
		});
	},

	activateCustomer({ dispatch }, payload: {email: string, fromInfo: string}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'activateCustomer',
			value: payload,
		});
	},

	customerDetailsSubmitted({ dispatch }, payload: {customerDetails: any}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'customerDetailsSubmitted',
			value: payload,
		});
	},

	clckdSaveReservtionWthInvalidInfo({ dispatch }, payload: {fromInfo: string}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'clckdSaveReservtionWthInvalidInfo',
			value: payload,
		});
	},

	clickedSaveReservation({ dispatch }, payload: {alsoCheckedSaveToAccountProfile: string, fromInfo: string}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'clickedSaveReservation',
			value: payload,
		});
	},

	saveReservation({ dispatch }, payload: {reservation: any}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'saveReservation',
			value: payload,
		});
	},

	userSelectedQuote({ dispatch }, payload: {theQuote: Quote}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'userSelectedQuote',
			value: payload,
		});
	},

	editServicesRemoveService({ dispatch }, payload: {serviceRemoved: Service}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'editServicesRemoveService',
			value: payload,
		});
	},

	editServicesAddService({ dispatch }, payload: {serviceAdded: Service}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'editServicesAddService',
			value: payload,
		});
	},

	clickFinalizeServiceChanges({ dispatch }, payload: {services: Service[]}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'clickFinalizeServiceChanges',
			value: payload,
		});
	},

	reservationEditButtonClick({ dispatch }, payload: {edit: string}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'reservationEditButtonClick',
			value: payload,
		});
	},

	clickedBookReservationFromTOS({ dispatch }, payload: {fromInfo: string}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'clickedBookReservationFromTOS',
			value: payload,
		});
	},

	clickedCancelReservationFromTOS({ dispatch }, payload: {fromInfo: string}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'clickedCancelReservationFromTOS',
			value: payload,
		});
	},

	clckdBookReservationAndShowingTOS({ dispatch }, payload: {fromInfo: string}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'clckdBookReservationAndShowingTOS',
			value: payload,
		});
	},

	reservationLookup({ dispatch }, payload: {email: string}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'reservationLookup',
			value: payload,
		});
	},

	clickedSaveOnInvalidPersonalInfoPage({ dispatch }, payload: {fromInfo: string}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'clickedSaveOnInvalidPersonalInfoPage',
			value: payload,
		});
	},

	clickedSavePersonalInfoPage({ dispatch }, payload: {fromInfo: string}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'clickedSavePersonalInfoPage',
			value: payload,
		});
	},

	bookingShowPromoCode({ dispatch }, payload: {}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'bookingShowPromoCode',
			value: payload,
		});
	},

	showQuoteDetails({ dispatch }, payload: {theQuote: Quote, fromClickingWidgetExtraInfo: string}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'showQuoteDetails',
			value: payload,
		});
	},

	clickedButtonToMoveToTab({ dispatch }, payload: {destinationTab: string}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'clickedButtonToMoveToTab',
			value: payload,
		});
	},

	duplicateReservationFound({ dispatch }, payload: {}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'duplicateReservationFound',
			value: payload,
		});
	},

	duplicateReservationFoundCanceled({ dispatch }, payload: {}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'duplicateReservationFoundCanceled',
			value: payload,
		});
	},

	duplicateReservationAccepted({ dispatch }, payload: {}) {
		dispatch('dispatchAnalyticsAction', {
			label: 'duplicateReservationAccepted',
			value: payload,
		});
	},

	signupSuccess({ dispatch }, payload: {isGoogleSignup: boolean } = { isGoogleSignup: false }) {
		dispatch('dispatchAnalyticsAction', {
			label: 'signupSuccess',
			value: payload,
		});
	},

	signupFailed({ dispatch }, payload: {error: any }) {
		dispatch('dispatchAnalyticsAction', {
			label: 'signupFailed',
			value: payload,
		});
	},

	locationChange({ dispatch }, payload: {newLocationCodeID: string }) {
		dispatch('dispatchAnalyticsAction', {
			label: 'locationChange',
			value: payload,
		});
	},

}

export default {
	namespaced: true,
	actions: Actions,
} as Module<State, StoreTypes.RootState>;
