import Vue from 'vue';
import Vuex from 'vuex';
import Customer from '@/models/Customer';
import analyticsPlugin from '@/plugins/analytics-vuex';
import analytics from '@/store/analytics';
import location from '@/store/location';
import reservation from '@/store/reservation';
import paymentInfo from '@/utilities/paymentInformation';
import {
	has,
	isString,
} from '@/utilities/helpers';
import googleEvents from './store/googleEvents';

Vue.use(Vuex);

const data = NP_PLUGIN_DATA || {};
const loggedInCustomer = new Customer((data.customer || {}).customer);
let activeCustomer = loggedInCustomer;
if (data && data.customer && data.customer.activeCustomer) {
	activeCustomer = new Customer(data.customer.activeCustomer);
}

const store = new Vuex.Store({
	plugins: [analyticsPlugin],
	modules: {
		analytics,
		location,
		reservation,
		googleEvents,
	},

	state: {
		ajaxUrl: '',
		activeCustomer,
		analytics: data.analytics || {},
		flash: data.flash,
		formErrors: data.formErrors || [],
		generalErrors: data.generalErrors || [],
		isLoading: false,
		location: data.location.locations,
		locationsMap: data.locationsMap,
		loggedInCustomer,
		loginMarketing: '',
		paths: data.paths,
		activateCustomer: data.activateCustomer || false,
		reservation: data.reservation || {},
		settings: data.settings || {},
		termsOfService: data.termsOfService || '',
		recurringTerms: data.recurringTerms || '',
		utmData: data.utmData || '',
		googleDebugMode: data.googleDebugMode || false,
	},
	getters: {
		hasErrors(state: any): boolean {
			return state.formErrors.length > 0 || state.generalErrors.length > 0;
		},
		corporation(): App.Corporation | null {
			const corporation = (window as any).corporation;
			if (!corporation) {
				return {} as App.Corporation;
			}

			return corporation as App.Corporation;
		},
		corporationName(state, getters): string {
			const corporation = getters.corporation;
			if (!has('name', corporation)) {
				return '';
			}

			return corporation.name
				.replace('-', ' ')
				.replace(/^([a-z])|\s+([a-z])/g, ($1: string) => $1.toUpperCase());
		},
		customer(state, getters): Customer {
			return state.activeCustomer;
		},
		locationsMap(state, getters): any {
			return state.locationsMap;
		},
		isLoggedIn(state, getters): boolean {
			return !!getters.customer.customer;
		},

		paymentImage(state, getters, rootState): (type: string) => string {
			return (type: string): string => {
				if (has(`${type}.image`, paymentInfo)) {
					return `${rootState.paths.images}${(paymentInfo as any)[type].image}`;
				}

				return '';
			};
		},
	},
	mutations: {
		addError(state, error: any) {
			if (!error) {
				return;
			}

			if (error.name && error.text) {
				state.formErrors.push({ field: error.name, msg: error.text });
			} else if (error.text) {
				state.generalErrors.push({ msg: error.text, type: error.type || 'general' });
			} else if (isString(error)) {
				state.generalErrors.push({ msg: error, type: 'general' });
			}
		},
		clearErrors(state: StoreTypes.RootState) {
			state.formErrors = [];
			state.generalErrors = [];
		},
		setLoggedIn(state: StoreTypes.RootState, customer: Customer) {
			state.loggedInCustomer = customer;
			state.activeCustomer = customer;
		},
		setLoading(state: StoreTypes.RootState, isLoading: boolean) {
			state.isLoading = isLoading;
		},
		setErrors(state: any, errors: any) {
			state.formErrors = [];
			state.generalErrors = [];

			if (has('response.data.errors', errors)) {
				({ errors } = errors.response.data);
			}

			if (has('errors', errors)) {
				({ errors } = errors);
			}

			if (Array.isArray(errors)) {
				errors.forEach((error: any) => {
					if (error.name && error.text) {
						state.formErrors.push({ field: error.name, msg: error.text });
					} else if (error.text) {
						state.generalErrors.push({
							msg: error.text,
							type: error.type || 'general',
						});
					}
				});
			}

			state.loading = false;
		},
		setActivateCustomer(state: StoreTypes.RootState, activateCustomer: boolean) {
			state.activateCustomer = activateCustomer;
		},
	},
	actions: {
		failedRequest({ commit }, errors: any) {
			commit('setLoading', false);
			commit('setErrors', errors);
		},

		startRequest({ commit }) {
			commit('setLoading', true);
		},

		successfulRequest({ commit }, payload: any) {
			commit('setLoading', false);
		},
	},
});

export default store;
