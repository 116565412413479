





































import Vue, { VueConstructor } from 'vue';
import {
	mapActions, mapGetters, mapState,
} from 'vuex';
import { debounce } from '@/utilities/helpers';

import { FormatAddress } from '../utilities/formatters';
import NpMultiselect from './NpMultiselect.vue';

interface LocationSelect {
	customerLocations: Locations.Location[];
	recurringLocations: Locations.Location[];
	reservationLocations: Locations.Location[];
	hasLocation: boolean;
	location: Locations.Location;
	locations: Locations.Location[];
	subLocations: Locations.Location[];
	setLocation: (location: string) => void;
}

export default (Vue as VueConstructor<Vue & LocationSelect>).extend({
	components: { NpMultiselect },

	props: {
		availableLocations: {} as () => Locations.Location[],
		customer: {
			type: Boolean,
			default: false,
		},
		id: {
			type: String,
			default: 'location-selector',
		},
		locked: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: 'all',
		},
	},

	data() {
		return {
			value_change: false,
			reservation_count: 0,
			locationLimit: 100 as number,
			windowWidth: window.innerWidth - 32 as number,
		};
	},

	computed: {
		...mapState('location', ['locations']),
		...mapGetters('location', ['customerLocations', 'hasLocation', 'location', 'recurringLocations', 'reservationLocations', 'subLocations']),

		minWidth(): string {
			let minWidth = 280;

			if (this.windowWidth < minWidth) {
				minWidth = this.windowWidth;
			}

			return `${minWidth}px`;
		},

		settings(): Locations.Settings {
			return this.$store.getters['location/settings'];
		},


		model: {
			get(): Locations.Location {
				switch (this.type) {
					case 'customer':
						return this.customerLocations.find((location: any) => (
							(+location.code === +this.location.code || location.code === this.location.code)
						&& location.codeID === this.location.codeID
						))
							|| ({} as Locations.Location);
					case 'recurring':
						return this.recurringLocations.find((location: any) => (
							(+location.code === +this.location.code || location.code === this.location.code)
						&& location.codeID === this.location.codeID))
							|| ({} as Locations.Location);
					case 'reservation':
						if (this.reservationLocations[0].details.name !== 'Select a Location' && this.settings.force_select_location === 1 && sessionStorage.getItem('count') === '1') {
							this.value_change = true;
						}
						if (this.settings.force_select_location === 1 && !this.value_change && this.reservationLocations[0].details.name === 'Select a Location') {
							return this.reservationLocations;
						}
						if (this.reservationLocations[0].details.name === 'Select a Location' && this.value_change) {
							this.reservationLocations.shift();
						}
						return this.reservationLocations.find((location: any) => (
							(+location.code === +this.location.code || location.code === this.location.code)
							&& location.codeID === this.location.codeID))
								|| ({} as Locations.Location);


					case 'all':
					default:
						return this.location;
				}
			},
			set(value: Locations.Location) {
				this.$nextTick(() => {
					this.value_change = true;
				});
				if (value && value.codeID && value.codeID !== this.location.codeID) {
					this.$store.dispatch('googleEvents/locationChange', {
						newLocationCodeID: `${value.code}-${value.subLocation}`,
					});
					this.setLocation(value.codeID);
					this.$emit('updated', value.codeID, value);
				}
			},
		},

		selectableLocations(): Locations.Location[] {
			if (Array.isArray(this.availableLocations) && this.availableLocations.length) {
				return this.availableLocations;
			}

			switch (this.type) {
				case 'customer':
					return this.customerLocations;
				case 'sub':
					return this.subLocations;
				case 'recurring':
					return this.recurringLocations;
				case 'reservation':
					if (this.settings.force_select_location === 1 && !this.value_change && this.reservation_count < 1 && sessionStorage.getItem('count') !== '1') {
						this.location.codeID = '1000000';
						this.reservationLocations.unshift({ details: { name: 'Select a Location' } })
						sessionStorage.setItem('count', '1');
						this.reservation_count = 1;
					}
					return this.reservationLocations;
				case 'all':
				default:
					return this.locations;
			}
		},
	},

	mounted() {
		if (this.selectableLocations.length === 1 && this.subLocations.length === 1) {
			[this.model] = this.selectableLocations;
		}

		if (this.model && this.model.codeID !== this.location.codeID) {
			this.setLocation(this.model.codeID as string);
		}

		if (!this.selectableLocations.find((location) => +location.subLocation === +this.location.subLocation)) {
			this.setLocation(this.selectableLocations[0].codeID as string);
		}

		window.onresize = debounce(() => {
			this.$nextTick(() => {
				this.windowWidth = window.innerWidth - 32;
			});
		}, 500, { leading: false, trailing: true });

		if (this.settings.display_all_quotes_from_locations) {
			this.setLocations(this.selectableLocations);
		}
	},

	methods: {
		...mapActions('location', ['setLocation', 'setLocations']),

		locationAddress(location: Locations.Location): string {
			return FormatAddress({
				address: location.address,
				city: location.city,
				state: location.state,
				zip: location.zip,
				country: location.country,
			}, ' ');
		},
	},
});
