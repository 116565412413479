/**
 * Recurring group model
 */
import Model from '@/models/Model';
import ParkingZone from '@/models/ParkingZone';

export default class RecurringGroup extends Model {
	public code_id: number = 0;
	public frequency: number = 1;
	public company_id: number | null = null;
	public zone_id: number | null = null;
	public initial_fee: number | null = null;
	public initial_deposit: number | null = null;
	public monthly_rate: number | null = null;
	public airport_fee: number | null = null;
	public sales_tax: number | null = null;
	public ar_billing = false;
	public active = true;
	public created_datetime = new Date();
	public timestamp = new Date();
	public user_id: number | null = null;
	public code: string | null = null;
	public name: string = '';
	public location: number | null = null;
	public parkingZone: ParkingZone | null = null;
	public line_items: any[] = [];
	public target_date: Date = new Date();
	public spots: number = 1;

	public constructor(data: any = {}) {
		super(data);
		this.initialize(data);

		if (typeof data.line_items === 'string') {
			this.line_items = JSON.parse(data.line_items);
		}
	}

	public get total(): number {
		let total = this.monthly_rate || 0;

		total += this.sales_tax || 0;
		total += this.airport_fee || 0;

		if (this.line_items) {
			this.line_items.forEach((item) => {
				total += item.amount;
			});
		}

		return total;
	}
}
