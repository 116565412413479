/**
 * Parking zone model
 */
import Model from '@/models/Model';

export default class ParkingZone extends Model {
	public zone_id: number = 0;
	public tag: string | null = null;
	public user_id: number | null = null;
	public rate: number | null = null;
	public alt_rate: number | null = null;
	public initial_fee: number | null = null;
	public initial_deposit: number | null = null;
	public monthly_rate: number | null = null;
	public frequency: number = 1;
	public airport_fee: number | null = null;
	public sales_tax: number | null = null;
	public capacity: number | null = null;
	public overbook: number | null = null;
	public serviceable: boolean = false;
	public active: boolean = true;
	public timestamp = new Date();
	public name: string = '';
	public phone: string | null = null;
	public email: string | null = null;
	public address1: string | null = null;
	public address2: string | null = null;
	public city: string | null = null;
	public state: string | null = null;
	public zip: string | null = null;
	public parent_zone: number | null = null;
	public threshold: number | null = null;
	public location: number | null = null;
	public recurring_spots: number | null = null;
	public target_date: Date = new Date();
	public spots: number = 1;


	public constructor(data: any = {}) {
		super(data);
		this.initialize(data);
	}

	public get total(): number {
		let total = this.monthly_rate || 0;
		total += this.sales_tax || 0;
		total += this.airport_fee || 0;

		return total;
	}
}
